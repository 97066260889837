import MyAccount from "./layout/ui/Dashboard/MyAccount/MyAccount";
import MyTournaments from "./layout/ui/Dashboard/MyTournaments/MyTournaments";
import MyTournament from "./layout/ui/Dashboard/MyTournaments/MyTournament";
import NotFound from "./layout/ui/SinglePage/404";
import dateFormat from 'dateformat';
import { Container, Header, Icon, IconGroup, Label, Message, Popup } from 'semantic-ui-react'
import Members from "./layout/ui/Dashboard/MyAccount/Members";
import moment from "moment";
import { CSVLink } from "react-csv";
import Marquee from "react-fast-marquee";
import AddTournamentStep from "./layout/ui/Dashboard/AddTournament/AddTournamentStep";
import Duel1vs1 from "./layout/ui/Dashboard/MyAccount/Duel1vs1";
import i18next from "i18next";




export function projectView(View) {

    window.localStorage.setItem('gView', JSON.stringify(View));
    switch (View.myView) {

        case "AddTournament": return <AddTournamentStep ViewProps={View} />;// def page
        case "MyAccount": return <MyAccount ViewProps={View} />;// def page
        case "MyTournaments": return <MyTournaments ViewProps={View} />;// def page
        case "MyTournament": return <MyTournament ViewProps={View} />;// def page


        case "Members": return <Members ViewProps={View} />;// def page
        case "Duel1vs1": return <Duel1vs1 ViewProps={View} />;// def page

        default: return <NotFound />
    }
}

export function SCezarencrypt(text, shift = 7) {
    var result = "";


    //loop through each caharacter in the text
    for (var i = 0; i < text.length; i++) {

        //get the character code of each letter
        var c = text.charCodeAt(i);

        // handle uppercase letters
        if (c >= 65 && c <= 90) {
            result += String.fromCharCode((c - 65 + shift) % 26 + 65);

            // handle lowercase letters
        } else if (c >= 97 && c <= 122) {
            result += String.fromCharCode((c - 97 + shift) % 26 + 97);

            // its not a letter, let it through
        } else {
            result += text.charAt(i);
        }
    }

    return result;
}

export function SCezardecrypt(text, shift = 7) {
    var result = "";
    shift = (26 - shift) % 26;
    result = SCezarencrypt(text, shift);
    return result;
}

export function changeFormatDate(mydate) {
    try {
        mydate ? mydate = dateFormat(mydate, "yyyy-mm-dd") : mydate = ""
    } catch (error) {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            console.log(error)
        }
    }
    return mydate;

}
export function changeFormatDateToTime(mydate) {
    try {
        mydate ? mydate = dateFormat(mydate, "HH:MM") : mydate = ""
    } catch (error) {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            console.log(error)
        }
    }
    return mydate;

}
export function changeFormatDateTime(mydate) {
    try {
        mydate ? mydate = dateFormat(mydate, "yyyy-mm-dd'T'HH:MM:ss") : mydate = ""
    } catch (error) {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            console.log(error)
        }
    }
    return mydate;

}

export function changeFormatDateTimeDB(mydate) {
    try {
        mydate ? mydate = dateFormat(mydate, "yyyy-mm-dd HH:MM:ss") : mydate = ""
    } catch (error) {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            console.log(error)
        }
    }

    return mydate;

}

export function changeFormatDateTimeBS(mydate) {
    try {
        mydate ? mydate = dateFormat(mydate, "yyyy-mm-dd HH:MM") : mydate = ""
    } catch (error) {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            console.log(error)
        }
    }

    return mydate;

}

export function checkTimeVal(DateFrom, DateTo) {
    try {
        if ((Date.parse(DateTo) - Date.parse(DateFrom)) <= 0) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false
    }
}

export function checkDateStartBeforeNow(endDate) {
    try {
        let date1 = new Date();
        let date2 = new Date(endDate);
        var timediff = date2 - date1;
        if (timediff < 0) {
            return false
        } else {
            return true
        }
    } catch (error) {
        return false
    }
}

export function checkDateOnlyStartBeforeNow(endDate) {
    try {
        let dateObj = new Date();
        let myDate = (dateObj.getUTCFullYear()) + "-" + (dateObj.getMonth() + 1) + "-" + (dateObj.getUTCDate());

        let date1 = new Date(myDate);
        let date2 = new Date(endDate);
        var timediff = date2 - date1;

        if (timediff < 0) {
            return false
        } else {
            return true
        }
    } catch (error) {
        return false
    }
}


export function packageColor(co) {
    //brazowy
    if (co === 1) {
        return <Icon name='certificate' size='large' style={{ color: "#8B4513" }} />
    }
    //srebrny
    else if (co === 2) {

        return <Icon name='certificate' size='large' style={{ color: "silver" }} />
    }
    //złoty
    else if (co === 3) {

        return <Icon name='certificate' size='large' style={{ color: "#cca300" }} />
    }//diamentowy
    else if (co === 4) {

        return <Icon name='certificate' size='large' style={{ color: "#ab56e4" }} title="SUBSKRYPCJA" />

    } else if (co === 5) {

        return <IconGroup size='large' title="SUBSKRYPCJA PLUS">
            <Icon name='certificate' style={{ color: "#ab56e4" }} />
            <Icon corner name='add' style={{ color: "#ab56e4", fontSize: 13, paddingLeft: 5 }} />
        </IconGroup>
    } else if (co === -1) {

        return <IconGroup size='large' style={{ marginRight: 5 }} title="SUBSKRYPCJA LITE">
            <Icon name='certificate' style={{ color: "#ab56e4" }} />
            <Icon corner name='leaf' style={{ color: "#ab56e4", fontSize: 13, paddingLeft: 5 }} />

        </IconGroup>
    } else {
        return ""
    }

}
export function showEloP(startData, stopData) {

    try {
        let startDate = moment(startData, "YYYY-MM-DD");
        let endDate = moment(stopData, "YYYY-MM-DD");
        let testDate = moment(new Date(), "YYYY-MM-DD");

        startDate = moment(startDate, "YYYY-MM-DD").add(-100, 'days');
        endDate = moment(endDate, "YYYY-MM-DD").add(1, 'days');

        if (testDate.isBetween(startDate, endDate)) {
            return true
        } else {
            return false
        }
    } catch (error) {
        return false
    }

}

export function LiveIcon(startData, stopData) {

    try {

        let startDate = moment(startData, "YYYY-MM-DD HH:mm:ss");
        let endDate = moment(stopData, "YYYY-MM-DD HH:mm:ss");
        let testDate = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
        let tmpdiff = endDate - startDate

        if (tmpdiff === 0) {
            endDate = moment(endDate, "YYYY-MM-DD HH:mm:ss").add(1, 'days');;
        }

        if (testDate.isBetween(startDate, endDate)) {
            return true
        } else {
            return false
        }
    } catch (error) {
        return false
    }

}

export function addDateToDate(mdate, mday) {
    try {
        return moment(mdate, "YYYY-MM-DD HH:mm:ss").add(mday, 'days').format('YYYY-MM-DD HH:mm:ss')
    } catch (err) {
        console.log(err)
        return "?"
    }
}

export function LiveIcon2() {
    return <div className="liveIcon"><div className="blinkmpN2" style={{ width: 68, position: "relative" }}>LIVE <Icon size="small" style={{ position: "absolute", left: 6, top: 10 }} name="circle" /></div> </div>
}

export function LiveIcon3(txt) {
    const mtxt = <span style={{ fontSize: 13, marginLeft: 20, marginRight: 20 }}>{txt}</span>
    return <div className="liveIcon" style={{ width: "100%", position: "relative", margin: "0 auto" }}>
        <div className="blinkmpN2new" style={{ width: "100%", position: "relative", margin: "0 auto" }}>

            <Marquee speed={30}  >
                <div style={{ position: "relative", width: 60 }}>LIVE  <Icon size="small" style={{ position: "absolute", left: 6, top: 5 }} name="circle" /></div>
                <div style={{ marginLeft: 30, marginRight: 20 }}>|{mtxt}|</div>
                <div style={{ position: "relative", width: 60 }}>LIVE <Icon size="small" style={{ position: "absolute", left: 6, top: 5 }} name="circle" /></div>
                <div style={{ marginLeft: 30, marginRight: 20 }}>|{mtxt}|</div>
                <div style={{ position: "relative", width: 60 }}>LIVE <Icon size="small" style={{ position: "absolute", left: 6, top: 5 }} name="circle" /></div>
                <div style={{ marginLeft: 30, marginRight: 20 }}>|{mtxt}|</div>
                <div style={{ position: "relative", width: 60 }}>LIVE <Icon size="small" style={{ position: "absolute", left: 6, top: 5 }} name="circle" /></div>
                <div style={{ marginLeft: 30, marginRight: 20 }}>|{mtxt}|</div>
                <div style={{ position: "relative", width: 60 }}>LIVE <Icon size="small" style={{ position: "absolute", left: 6, top: 5 }} name="circle" /></div>
                <div style={{ marginLeft: 30, marginRight: 20 }}>|{mtxt}|</div>
                <div style={{ position: "relative", width: 60 }}>LIVE <Icon size="small" style={{ position: "absolute", left: 6, top: 5 }} name="circle" /></div>
                <div style={{ marginLeft: 30, marginRight: 20 }}>|{mtxt}|</div>
                <div style={{ position: "relative", width: 60 }}>LIVE <Icon size="small" style={{ position: "absolute", left: 6, top: 5 }} name="circle" /></div>
                <div style={{ marginLeft: 30, marginRight: 20 }}>|{mtxt}|</div>
                <div style={{ position: "relative", width: 60 }}>LIVE <Icon size="small" style={{ position: "absolute", left: 6, top: 5 }} name="circle" /></div>
                <div style={{ marginLeft: 30, marginRight: 20 }}>|{mtxt}|</div>
            </Marquee></div>
    </div>
}

export function changePageName(newName = "Generator drabinek turniejowych, tabel grupowych oraz lig") {
    document.title = newName + " - turniej-generator.pl";
}

export function seperatorTxtUrl() {
    return "_"
}

export function currentDatatime() {
    return dateFormat(new Date(), "HH:MM:ss")
}


export function getlinkcycle(cycle_id, cycle_name, subtname, onlyName = 0, noObj = false) {
    try {
        if (noObj) {
            return "/cykl/" + cycle_id + "/" + cycle_name.split(' ').join(seperatorTxtUrl()) + "/" + subtname.split(' ').join(seperatorTxtUrl())
        } else {
            return <span><a className={"tllink"} href={"/cykl/" + cycle_id + "/" + cycle_name.split(' ').join(seperatorTxtUrl()) + "/" + subtname.split(' ').join(seperatorTxtUrl())} target="_blank" rel="noopener noreferrer">{onlyName ? cycle_name : "turniej-generator.pl/cykl/" + cycle_id + "/" + cycle_name.split(' ').join(seperatorTxtUrl()) + "/" + subtname.split(' ').join(seperatorTxtUrl())}</a></span>
        }
    } catch (error) {
        return ""
    }
}

export function getlinkachievement(id, countProfile, totalProfile) {
    try {
        return <span><a className={"tllink2"} href={"/osiagniecia/" + id} rel="noopener noreferrer">Osiagnięte przez {countProfile >= 0 ? countProfile : "n/n"} profili {getPercentValue(countProfile, totalProfile)}</a></span>
    } catch (error) {
        return ""
    }
}


export function getlinksubt(tournament_id, subtournament_id, namelink = null, noObj = false) {
    try {
        if (noObj) {
            return "/tsv/" + tournament_id + "/" + subtournament_id
        } else {
            return <span><a className={"tllink"} href={"/tsv/" + tournament_id + "/" + subtournament_id} target="_blank" rel="noopener noreferrer"> {namelink ? namelink : "turniej-generator.pl/tsv/" + tournament_id + "/" + subtournament_id}</a></span>
        }
    } catch (error) {
        return ""
    }
}

export function getlinkActivity(activity_id) {
    try {

        return <span><a className={"tllink"} href={"/activity/" + numberToCharTG(activity_id)} target="_blank" rel="noopener noreferrer"> {process.env.REACT_APP_PUBLIC_URL + "/activity/" + numberToCharTG(activity_id)}</a></span>
    } catch (error) {
        return ""
    }
    /*
     try {
        return <span><a href={"/turniej/" + tournament_id} target="_blank" rel="noopener noreferrer"> {"turniej-generator.pl/turniej/" + tournament_id}</a></span>
    } catch (error) {
        return ""
    }*/
}

export function getlinktournamentTV(tournament_id, withhttp = false, namelink = null) {
    try {
        let withhttptmp = "";
        if (withhttp) {
            withhttptmp = "https://turniej-generator.pl";
        }
        return <span><a className={"tllink"} href={withhttptmp + "/viewtv/10/" + numberToCharTG(tournament_id)} target="_blank" rel="noopener noreferrer"> {namelink ? namelink : "turniej-generator.pl/viewtv/10/" + numberToCharTG(tournament_id)}</a></span>
    } catch (error) {
        return ""
    }
    /*
     try {
        return <span><a href={"/turniej/" + tournament_id} target="_blank" rel="noopener noreferrer"> {"turniej-generator.pl/turniej/" + tournament_id}</a></span>
    } catch (error) {
        return ""
    }*/
}

export function getlinktournament(tournament_id, withhttp = false, namelink = null) {
    try {
        let withhttptmp = "";
        if (withhttp) {
            withhttptmp = "https://turniej-generator.pl";
        }
        return <span><a className={"tllink"} href={withhttptmp + "/tg/" + numberToCharTG(tournament_id)} target="_blank" rel="noopener noreferrer"> {namelink ? namelink : "turniej-generator.pl/tg/" + numberToCharTG(tournament_id)}</a></span>
    } catch (error) {
        return ""
    }
    /*
     try {
        return <span><a href={"/turniej/" + tournament_id} target="_blank" rel="noopener noreferrer"> {"turniej-generator.pl/turniej/" + tournament_id}</a></span>
    } catch (error) {
        return ""
    }*/
}

export function getlinkplayerprofile(member_id, member_name, color = 1, fontSize = 16, mtarget = "_self") {
    try {
        return member_id > 0 ? <><a className={color===1 ? "tllink" : color===2 ? "tllinkv2" : ""} style={{ fontSize: fontSize }} target={mtarget} href={"/member/" + member_id + "/" + normalizeString(member_name).split(' ').join(seperatorTxtUrl())}>{member_name}</a> </> : <span style={{ fontSize: fontSize }} className='tllinkNoColor'>{member_name}</span>
    } catch (error) {
        return ""
    }
}

export function txtCase(str) {
    try {
        return str.split(" ").map((str) => str = str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()).join(" ");
    } catch (error) {
        return str
    }
}

export function checkTimeExist(date) {
    try {
        let d = date.split(" ")
        if (d[1] === "00:00:00" || d[1] === "00:00") {
            return d[0]
        } else {
            return date
        }
    } catch (error) {
        return date
    }
}


export function addHastTagToUrl(data) {
    try {
      window.location.hash = data ? "view="+data : ""
    } catch (error) {
        console.log(error)
    }
}

export function changeInitialScale(ile) {
    if (ile === 1) {
        document.querySelector("meta[name=viewport]").setAttribute(
            'content',
            'width=device-width, initial-scale=' + ile + ', user-scalable=0, minimum-scale=1, maximum-scale=1');
    } else {
        document.querySelector("meta[name=viewport]").setAttribute(
            'content',
            'width=device-width, initial-scale=' + ile + ', user-scalable=1, minimum-scale=0.1, maximum-scale=2');
    }

}

export function normalizeString(str) {
    const iMap = {
        'ð': 'd',
        'ı': 'i',
        'Ł': 'L',
        'ł': 'l',
        'ø': 'o',
        'ß': 'ss',
        'ü': 'ue'
    };
    const iRegex = new RegExp(Object.keys(iMap).join('|'), 'g')
    return str
        .replace(iRegex, (m) => iMap[m])
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, '').replace(/ /g, '_').replace(/\./g, '_').replace(/\//g, '')
        .replace(/:/g, '').replace(/;/g, '').replace(/\\/g, '').replace(/\|/g, '');
}

export function normalizeStringURL(str) {
    if(str && str.length>0){
        const iMap = {
            'ð': 'd',
            'ı': 'i',
            'Ł': 'L',
            'ł': 'l',
            'ø': 'o',
            'ß': 'ss',
            'ü': 'ue'
        };
        const iRegex = new RegExp(Object.keys(iMap).join('|'), 'g')
        return str
            .replace(iRegex, (m) => iMap[m])
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, '').replace(/ /g, '').replace(/\./g, '').replace(/,/g, '').replace(/\//g, '')
            .replace(/:/g, '').replace(/;/g, '').replace(/\\/g, '').replace(/\|/g, '').replaceAll(" ", '').toLowerCase();
    }else{
        return str
    }

   
}

export function normalizeStringURLIMG(str) {
    if(str && str.length>0){
        const iMap = {
            'ð': 'd',
            'ı': 'i',
            'Ł': 'L',
            'ł': 'l',
            'ø': 'o',
            'ß': 'ss',
            'ü': 'ue'
        };
        const iRegex = new RegExp(Object.keys(iMap).join('|'), 'g')
        return str
            .replace(iRegex, (m) => iMap[m])
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, '').replace(/ /g, '').replace(/,/g, '').replace(/\//g, '')
            .replace(/:/g, '').replace(/;/g, '').replace(/\\/g, '').replace(/\|/g, '').replaceAll(" ", '').toLowerCase();
    }else{
        return str
    }

   
}

export function checkScoreOK(pointsCalcSystemId, score1, score2) {

    /*
1	Domyślny
2	Tenis stołowy
3	Siatkówka
4	Piłka nożna
5	Dart
6	Badminton
7	E-sport
8	Tenis ziemny
9	Koszykówka
    */
    try {

        if (pointsCalcSystemId === 2) {
            if (score1 > 10) {
                return false
            }
            if (score2 > 10) {
                return false
            }
            return true
        }
        if (pointsCalcSystemId === 8) {
            if (score1 > 30) {
                return false
            }
            if (score2 > 30) {
                return false
            }

            return true
        }
        return true
    } catch (error) {
        return true
    }

}


export function numberToCharTG(num) {
    var s = '';
    var myArr = String(Number(num) + 10000).split("").map((num) => {
        return Number(num)
    })
    for (let i = 0; i < myArr.length; i++) {
        if (myArr[i] === 0) {//z
            s = s + String.fromCharCode(26 + 96);
        } else {
            s = s + String.fromCharCode(myArr[i] + 96);
        }

    }

    return s || undefined;
}

export function charTGToNumber(text) {

    var s = '';
    var myArr1 = String((text.toLowerCase())).split("").map((text) => {
        return text
    })
    for (let i = 0; i < myArr1.length; i++) {
        if (myArr1[i] === 'z') {//0
            s = s + '' + 0;
        } else {
            s = s + '' + (myArr1[i].charCodeAt(0) - 96);
        }

    }
    return Number(s) - 10000 || undefined;
}



export function loadingShow() {
    return <Container textAlign='center' style={{ paddingTop: 20 }}>
        <Header as='h4' icon>
            <Icon loading name='spinner' />
            Trwa ładowanie
            <Header.Subheader>
                proszę czekać...
            </Header.Subheader>
        </Header>
    </Container>
}

export function getAddressLocationGoole(value, retlon) {
    try {
        if (value) {
            let ret = JSON.parse(value)
            if (retlon) {
                return 0
            } else {
                return ret.description + " (" + ret.address + ")"
            }
        } else {
            return "n/n"
        }
    } catch (e) {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            console.log(e)
        }
        return value
    }
}


export function saveCsvTab(arr, filename) {
    if (arr && arr.length > 0) {
        filename = filename.toLowerCase().replace(/ /g, '');
        return <CSVLink data={arr} separator={";"} filename={filename} className='tllink hiddenPrint'><Icon name="download" title="ZAPISZ CSV" bordered /></CSVLink>
    } else {
        return ""
    }

}

export function getLimitUsersPackage(mtype) {
    if (mtype === 0) {
        return Number(process.env.REACT_APP_IN_LIMITBROWN)
    } else if (mtype === 1) {
        return Number(process.env.REACT_APP_IN_LIMITSILVER)
    } else if (mtype === 2) {
        return Number(999)
    } else if (mtype === -1) {
        return Number(50)
    } else {
        return Number(mtype)
    }
}

export function getCyklPackage() {
    return <Container textAlign='center' style={{ margin: 10, padding: 10, fontSize: 13, lineHeight: "14px", backgroundColor: "#f7f7f7" }}>
        Cykl życia pakietów
        <div>{packageColor(1)} <Icon name="arrow right" /> <b>1 GTL</b> <Icon name="arrow right" /> {packageColor(2)}</div>
        <div>{packageColor(1)} <Icon name="arrow right" /> <b>1 GTL</b> <Icon name="arrow right" /> {packageColor(2)} <Icon name="arrow right" /> <b>1 GTL</b> <Icon name="arrow right" /> {packageColor(3)}</div>
        <div>{packageColor(1)} <Icon name="arrow right" /> <b>2 GTL</b> <Icon name="arrow right" /> {packageColor(3)}</div>
        Pakiet można podnosić w dowolnym momencie trwania/tworzenia turnieju lub ligi w zależności od potrzeb organizatora.
    </Container>
}


export function removeEmojis(string) {
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, '');
}

export function removeSpecialChars(string) {
    // eslint-disable-next-line
    return string.replace(/[&\/\\#,+$~%'";*?<>{}|[\]]/g, '');
}

export function checkSizeFile(file) {
    try {
        let totalBytes = file.size
        let totalKB = Math.floor(totalBytes / 1000)
        console.log(file, totalKB)
        if (totalKB > Number(process.env.REACT_APP_IN_SIZEFILEKB)) {
            //if(totalKB>Number(100)){
            return false
        } else {
            return true
        }
    } catch (ee) {
        return false
    }

}


export function checkIsREFTOKENOn() {
    if (Number(process.env.REACT_APP_IN_REFTOKENON) === 1) {
        return true
    } else {
        return false
    }
}


export function getStosunekPTK(wins, losers) {
    try {

        if (Number(wins) === 0) {
            return (Math.round((Number(wins)) * 100) / 100).toFixed(2)
        } else if (Number(losers) === 0) {
            return (Math.round((Number(wins)) * 100) / 100).toFixed(2)
        } else if (Number(losers) === 0 && Number(wins) === 0) {
            return (0).toFixed(2)
        } else {
            return (Math.round((Number(wins) / Number(losers)) * 100) / 100).toFixed(2)
        }
    } catch (err) {
        return wins - losers
    }
}

export function getSubscriptionsMonthDate(dt) {
    try {
        return moment(dt).add(31, 'days').format('YYYY-MM-DD');
    } catch (err) {
        return dt + "err"
    }
}

export function mobileW() {
    return 800;
}

export function mobileWSmall() {
    return 600;
}


export function subscripteUserDateURL(val) {
    try {
        const arrdatas = {
            355: "/kspodwawelski",
            917: "/komorow",
            1410: "/gppwpp",
            1202: "/griffinspin",
            1474: "/dart-rybnik",
            1478: "/ctw"
        }

        function getValueByKeyS(object, row) {
            return object[row];
        }

        return getValueByKeyS(arrdatas, val)
    } catch (err) {
        console.log(err)
        return null
    }
}

export function exportLocaleRPC() {
    return {
        firstDayOfWeek: 1,
        showMonthAfterYear: true,
        dayNames: ["Niedziela", "Poniedzia\u0142ek", "Wtorek", "\u015Aroda", "Czwartek", "Pi\u0105tek", "Sobota"],
        dayNamesShort: ["Nie", "Pon", "Wt", "\u015Ar", "Czw", "Pt", "Sob"],
        dayNamesMin: ["Nie", "Pon", "Wt", "\u015Ar", "Czw", "Pt", "Sob"],
        monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Pa\u017A", "Lis", "Gru"],
        monthNames: ["Stycze\u0144", "Luty", "Marzec", "Kwiecie\u0144", "Maj", "Czerwiec", "Lipiec", "Sierpie\u0144", "Wrzesie\u0144", "Pa\u017Adziernik", "Listopad", "Grudzie\u0144"],
        today: 'dzisiaj',
        clear: 'kasuj'
    }
}

export function getPercentValue(l1, l2) {
    try {
        // l1 liczba
        // l2 calosc
        let prc = ((parseInt(l1) / parseInt(l2)) * 100).toFixed(2);
        return <span style={{ fontSize: 11 }}><br />({prc} % wszystkich)</span>
    } catch {
        return ""
    }

}

export function getFormIcon(co) {
    if (co === "W") {
        return <div className='formaall formaW'>W</div>
    } else if (co === "P") {
        return <div className='formaall formaP'>P</div>
    } else if (co === "R") {
        return <div className='formaall formaR'>R</div>
    } else {
        return <div className='formaall forma'>?</div>
    }
}

export function getKeyJwtTG() {
    return "TurniejGenerator.Key"
}

export function blockEditScore(ms1, ms2, SystemID, match_details) {
    if (SystemID === 5 && ms1 !== null && ms2 !== null && match_details) {
        return true
    }
    return false;
}


export function getInfroMatchPlace(match_place) {
    try {
        if (match_place) {
            return <Popup
        content={<div style={{fontSize:12,lineHeight:"14px"}}>
        {JSON.parse(match_place).place_desc ? <><b>Miejsce pojedynku:</b> {JSON.parse(match_place).place_desc}<br/></> :""}
        {JSON.parse(match_place).match_dt ? <><b>Godzina pojedynku: </b>{JSON.parse(match_place).match_dt}<br/></> :""}
        </div>}
        key={JSON.parse(match_place).place_id}
        trigger={<div className="playingMatch"><Icon name="circle" />{JSON.parse(match_place).place_id}</div>}
      />
            
        } else {
            return ""
        }
    } catch (e) {
        return ""
    }

}

export function getInfroMatchPlace2(match_place) {
    try {
        if (match_place) {
            return <div style={{fontSize:12,lineHeight:"14px",textAlign:"left"}}> <Label size={"mini"} color="green"  title={"Pojedynek na: " + JSON.parse(match_place).place_id}>
                {JSON.parse(match_place).place_id}
                </Label> {JSON.parse(match_place).place_desc}<br/>
                {JSON.parse(match_place).match_dt ? <><Icon color="grey" name="calendar"/> {JSON.parse(match_place).match_dt}</> :""}
                </div>
        } else {
            return ""
        }
    } catch (e) {
        return ""
    }
}


export function loadingShowPlayerv2() {
    return <div>

        <Icon loading name='spinner' /><br />
        Oczekuje na dołączenie zawodnika


    </div>
}

export const matchplacetmpS = [
    { key: 1, text: 1, value: 1 },
    { key: 2, text: 2, value: 2 },
    { key: 3, text: 3, value: 3 },
    { key: 4, text: 4, value: 4 },
    { key: 5, text: 5, value: 5 },
    { key: 6, text: 6, value: 6 },
    { key: 7, text: 7, value: 7 },
    { key: 8, text: 8, value: 8 },
    { key: 9, text: 9, value: 9 },
    { key: 10, text: 10, value: 10 },
    { key: 11, text: 11, value: 11 },
    { key: 12, text: 12, value: 12 },
    { key: 13, text: 13, value: 13 },
    { key: 14, text: 14, value: 14 },
    { key: 15, text: 15, value: 15 },
    { key: 16, text: 16, value: 16 },
    { key: 17, text: 17, value: 17 },
    { key: 18, text: 18, value: 18 },
    { key: 19, text: 19, value: 19 },
    { key: 20, text: 20, value: 20 },
]

export function isEmpty(value) {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
}

export function getColorWinner(member1_score, member2_score, m) {
    try {
        if (member1_score !== null && member2_score !== null) {

            //member 1
            if (member1_score === member2_score) {
                return "#f0b30d"
            } else if (member1_score > member2_score && m === 1) {
                return "#fc7112"
            } else if (member1_score < member2_score && m === 1) {
                return "#ebebeb"
            } else if (member1_score > member2_score && m === 2) {
                return "#ebebeb"
            } else if (member1_score < member2_score && m === 2) {
                return "#fc7112"
            }

        }
        return ""
    } catch (e) {
        return ""
    }
}


export function noSubIsActive() {
    return <Container textAlign='center' style={{ paddingTop: 20 }}>
        <Header as='h4' icon>
            <Icon name='info circle' className='coffeAnime' />
            Brak aktywnej subskrypcji
            <Header.Subheader>
                <div style={{ fontSize: 12 }}>
                    Aktywuj pakiet - <b>Pakiet Diamentowy</b> lub <b>Pakiet Diamentowy Plus</b>
                </div>
            </Header.Subheader>
        </Header>
    </Container>
}

export function getInfoFreeBlock() {
    return "Funkcjonalność niedostępna w pakiecie brazowym, proszę podnieść pakiet";
}

export function checkisglicko() {
    // return process.env.REACT_APP_IN_GLICKO ? Number(process.env.REACT_APP_IN_GLICKO) : 1;
    return 0;
}

export function checkiscompanypay() {
    return process.env.REACT_APP_COMPANY_PAY ? Number(process.env.REACT_APP_COMPANY_PAY) : 0;
}

export function nettoToBrutto(value) {
    return (value*123/100).toFixed(2)
}
export function bruttoToNetto(value) {
    return (value*100/123).toFixed(2)
}

export function getLanguage(){
    try{
        return i18next.language
    }catch(e){
        return "pl"
    }
}



export function getInfoTab(){
    return <Message className='hiddenPrint' size='mini'><b>Kolejność w tabeli:</b> [1.Punktacja turniejowa], [2.Bezpośrednie pojedynki lub w przypadku większej ilości mała tabela], [3.Ratio]</Message>
}

export function getIconTournamentType(value){
    try{
        let jsond = JSON.parse(value)
     
        if(jsond.id.includes("CUP_N_")){
            return <Icon name="winner" color="grey"/>
        }else if(jsond.id.includes("CUP_")){
            return <Icon name="winner" color="blue"/>
        }else if(jsond.id.includes("_CUP")){
            return <><Icon name="table" color="orange" style={{margin:0,padding:0}}/><Icon name="long arrow alternate right" color="orange" style={{margin:0,padding:0}}/><Icon name="winner" color="orange" style={{margin:0,padding:0,marginRight:8}}/></>
        }else if(jsond.id==="GROUP" || jsond.id==="SWISS"){
            return <Icon name="table" color="brown"/>
        }else if(jsond.id==="LEAGUE"){
            return <Icon name="calendar alternate" color="green"/>
        }
    }catch(e){
        return ""
    }
}


export function getSkut(val, val1, val2, val3) {
    try {
      return Number((parseInt(val ? val : 0) / (parseInt(val1 ? val1 : 0) + parseInt(val2 ? val2 : 0) + parseInt(val3 ? val3 : 0))) * 100).toFixed(2)
    } catch (e) {
      return "?"
    }
  }


export function defJsonCFGConfigurator(){
    return {
        bgcolor: "#ffffff",
        bgcolor_stats: "#ededed",
        label_color_stats: "#c74142",
        dark: false,
        show_last_cycle: 1,
        show_all_tournaments: 1,
        show_all_cycles: 1,
        show_top10_member_elo: 1,
        show_top10_member_winners: 1,
        show_top10_member_podium: 1,
        show_members: 1,
        show_new_tournament: 1,
        show_statistics: 0,
        tournament_system:1,
        tournament_registration:0,
        tournament_name:1
      }
}

export function statusPayments(value,isObjL){
    try {
      if(value.toUpperCase()==="SUCCESS"){
        return isObjL ? <Label size='mini' color='green'>ZAPŁACONA</Label> : "ZAPŁACONA"
      }else if(value.toUpperCase()==="CREATED"){
        return isObjL ? <Label size='mini' >UTWORZONA</Label> : "UTWORZONA"
      }else if(value.toUpperCase()==="CANCELED"){
        return isObjL ? <Label size='mini' color='grey'>ANULOWANA</Label> : "ANULOWANA"
      }else if(value.toUpperCase()==="PENDING"){
        return isObjL ? <Label size='mini' color='blue'>OCZEKUJĄCA NA PŁATNOŚĆ</Label> : "OCZEKUJĄCA NA PŁATNOŚĆ"
      }else{
        return isObjL ? <Label size='mini' >{value}</Label> : value
      }
     
    }catch(e){
      return "?"
    }
  }