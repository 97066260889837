import bergerTable from 'berger-table-generator';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox, Grid, Header, Icon, Image, Message, Modal, Table } from 'semantic-ui-react'
import LogoTeamLigaGenerator from '../../../assets/logotgver1.svg';
import { numberToCharTG, saveCsvTab } from '../../../myfunctions';
import { useReactToPrint } from 'react-to-print';
import { QRCode } from 'react-qrcode-logo';
import tglh from '../../../assets/tglh.png';
import { useTranslation } from 'react-i18next';


function BracketGenHarm(propsRoot) {
    const [open, setOpen] = useState(false)
    const [idt, setidt] = useState(null)
    const [rowData, setrowData] = useState([])
    const [chboxg, setchboxg] = useState(false)
    const { t } = useTranslation();
    let tmp = []



    function reverseScore(a, b, countMatches) {
        if (Number(a) < Number(b)) {
            return countMatches[b].member_name + " vs " + countMatches[a].member_name
        } else {
            return countMatches[a].member_name + " vs " + countMatches[b].member_name
        }

    }

    function getTimeMatch(a, b, countMatches, rowmatches, retOnlyTime) {

        let m1 = countMatches[a].tournament_member_id
        let m2 = countMatches[b].tournament_member_id
        if (rowmatches) {
            for (const row of rowmatches) {

                if ((m1 === row.tournament_member1_id && m2 === row.tournament_member2_id) || (m2 === row.tournament_member1_id && m1 === row.tournament_member2_id)) {

                    if (row.match_place) {
                        if (retOnlyTime) {
                            return JSON.parse(row.match_place).match_dt
                        } else {
                            return <div style={{ fontSize: 12, lineHeight: "14px" }}>
                                {JSON.parse(row.match_place).place_desc ? <> <Icon color='grey' name="point" />{JSON.parse(row.match_place).place_desc}<br /> </> : ""}
                                {JSON.parse(row.match_place).match_dt ? <> <Icon color='grey' name="calendar" />{JSON.parse(row.match_place).match_dt}<br /> </> : ""}
                                
                            </div>
                        }

                    } else {
                        return ""
                    }
                }
            }
        }

    }

    function sortM(a, b) {
        return new Date(getDateS(a.match_dt)).getTime() - new Date(getDateS(b.match_dt)).getTime();
    }
    function sortMData(a, b) {
        return a.group_nr-b.group_nr || new Date(getDateS(a.match_dt)).getTime() - new Date(getDateS(b.match_dt)).getTime();
    }

    function getDateS(mdt){
        try{
            if(mdt===null || mdt===""){
                return "2555-01-01 12:00:00"
            }else{
                return mdt
            }
            
        }catch(e){
            return mdt
        }
    }
    
   
    function char_to_int(character) {
        switch(character){
            case 'I': return 1;
            case 'V': return 5;
            case 'X': return 10;
            case 'L': return 50;
            case 'C': return 100;
            case 'D': return 500;
            case 'M': return 1000;
            default: return -1;
        }
    }
    function romanToArabic(roman){
        if(roman == null)
            return -1;
        var totalValue = 0, 
            value = 0, // Initialise!
            prev = 0;
            
        for(var i=0;i<roman.length;i++){
            var current = char_to_int(roman.charAt(i));
            if (current > prev) {
                // Undo the addition that was done, turn it into subtraction
                totalValue -= 2 * value;
            }
            if (current !== prev) { // Different symbol?
                value = 0; // reset the sum for the new symbol
            }
            value += current; // keep adding same symbols
            totalValue += current;
            prev = current;
        }
        return totalValue;
    }

    function generatorMatchCount(countMatches, group_name, rowmatches) {
        try {
            let arr = bergerTable(countMatches.length)
            if (arr && arr.length > 0) {
                arr.forEach((row) => {
                    row.forEach((row1) => {
                        tmp.push({ group_nr:romanToArabic(group_name.replaceAll("Grupa ","")), group_name: group_name, match: reverseScore(row1.teamA, row1.teamB, countMatches), match_time: getTimeMatch(row1.teamA, row1.teamB, countMatches, rowmatches, false), match_dt: getTimeMatch(row1.teamA, row1.teamB, countMatches, rowmatches, true) })
                    })
                })
            } else {
                return ""
            }


            setrowData(tmp.sort(sortM))
        } catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps


        if (propsRoot.show) {
            setOpen(true)
        }
        if (propsRoot.linkTournament) {
            setidt(propsRoot.linkTournament)
        }
        if (propsRoot.countMatches && propsRoot.countMatches.length > 0 && propsRoot.countMatches[0].members && propsRoot.show) {

            tmp = [] // eslint-disable-line react-hooks/exhaustive-deps
            propsRoot.countMatches.forEach(element => {
                generatorMatchCount(element.members, element.group_name, element.matches)
            });

        }


        return () => {

        }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.show, propsRoot.linkTournament])// eslint-disable-line react-hooks/exhaustive-deps


    function closeModal() {
        setOpen(false)
        propsRoot.setopenModalHarm(false)
    }




    const componentRefPrint = useRef();
    const handlePrintTab = useReactToPrint({
        content: () => componentRefPrint.current,
    });

    let group_name = "Grupa I"

    function sgroup_name(val) {
        group_name = val
        return <div className='page-break'></div>
    }


    function checkboxchangedata(checked){
        let datatmp = rowData
        if(checked){
            setrowData(datatmp.sort(sortMData))
        }else{
            setrowData(datatmp.sort(sortM)) 
        }
        setchboxg(checked)
    }

    return (
        <>
            <Modal
                onClose={() => closeModal()}
                open={open}
                dimmer='blurring'
                closeIcon
                size='large'
            >

                <Modal.Content scrolling>

                    <div ref={componentRefPrint}>
                        <Grid unstackable style={{ marginTop: 5, marginBottom: 5 }}>
                            <Grid.Row>
                                <Grid.Column width={idt ? 12 : 16} textAlign="center" verticalAlign="middle">
                                    <Message>
                                        <Header as='h2' >
                                            <Header.Content>
                                                <Icon name="winner" />
                                                {t("WidokTurnieju.harmonogram_gier")}<br />
                                                {propsRoot.onlyView === 1 ? "" :
                                                    <Image style={{ width: 200 }} centered src={LogoTeamLigaGenerator} alt="logo" />
                                                }
                                            </Header.Content>
                                        </Header>
                                    </Message>
                                </Grid.Column>
                                {idt ?
                                    <Grid.Column width={4} textAlign="right" >

                                        <QRCode size={120} floated="right" value={"https://turniej-generator.pl/tg/" + numberToCharTG(idt)} logoImage={tglh} ecLevel={"H"} />

                                    </Grid.Column>
                                    : ""}

                            </Grid.Row>

                        </Grid>
                        {propsRoot.onlyView === 1 ? "" : <>
                            <Icon className='cursorPointer tllink hiddenPrint' title="DRUKUJ" bordered floated name="print" onClick={handlePrintTab} />
                            {saveCsvTab(rowData, "harmonogram.csv")}
                            <Checkbox toggle className='hiddenPrint' checked={chboxg} onChange={(e, d) => checkboxchangedata(d.checked)} label='Każda grupa na osobnej stronie' />
                            <style type="text/css" media="print">
                                {" @page { size: portrait; } "}
                            </style>
                        </>}
                        <Table style={{ marginTop: 5, fontSize: 14, lineHeight: "11px", marginBottom: 2 }} size='small' celled striped unstackable >
                            <Table.Header>
                                <Table.Row>

                                    <Table.HeaderCell singleLine width={2} textAlign='center'>{t("WidokTurnieju.grupa").toUpperCase()}</Table.HeaderCell>
                                    <Table.HeaderCell singleLine textAlign='center'>{t("WidokTurnieju.pojedynek").toUpperCase()}</Table.HeaderCell>
                                    <Table.HeaderCell singleLine width={5} textAlign='center'>{t("StronaDedykowana.data").toUpperCase()}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {rowData.length > 0 ?
                                    rowData.map((row, i) => <>
                                        {group_name !== row.group_name && chboxg ? sgroup_name(row.group_name) : ""}
                                        <Table.Row key={i}>
                                            <Table.Cell >{row.group_name}</Table.Cell>
                                            <Table.Cell textAlign='center'>{row.match}</Table.Cell>
                                            <Table.Cell textAlign='center'>{row.match_time}</Table.Cell>
                                        </Table.Row>

                                    </>
                                    )
                                    : ""}

                            </Table.Body>
                        </Table>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => closeModal()}>
                    {t("Zamknij").toUpperCase()}
                    </Button>

                </Modal.Actions>
            </Modal>
        </>

    )
}

export default BracketGenHarm;